//import Dashboard from './Dashboard.vue';
//import AgencyList from './AgencyList.vue';
//import AddNewAgent from './AddNewAgent.vue';
//import Intake from './Settings/IntakeList.vue';
//import AddNewMultipleAgentImport from './AddNewMultipleAgentImport.vue';
// import AddRegion from './Settings/AddRegion.vue';
//import RegionList from './Settings/RegionList.vue';
//import RegionalSupervisor from './Settings/RegionalSupervisor.vue';
//import CourseLevel from './Settings/CourseLevel.vue';
//import IntakeStatus from './Settings/IntakeStatus.vue';
//import InvoiceStatus from './Settings/InvoiceStatus.vue';
//import StudentStatus from './Settings/StudentStatus.vue';
//import AgentStatus from './Settings/AgentStatus.vue';
//import Commissions from './Settings/Commissions.vue';

//Gateway
//import AllApplication from './Gateway/AllApplication.vue';
//import AgentWiseStudentList from './Gateway/AgentWiseStudentList.vue';

//import BulkVerficationUpload from './Gateway/BulkVerficationUpload.vue';

// import AgencyWiseBulkStudent from './Gateway/AgencyWiseBulkStudent.vue';
//import Users from './Settings/Users.vue';
//import AddEditRole from './Settings/AddEditRole.vue';
//import RolePermissions from './Settings/RolePermissions.vue';
//import EmailConfiguration from './Settings/EmailConfiguration.vue';

//import AgentProfile from './AgentProfile.vue';
//import Logout from './Logout.vue';
//import CourseTuitionFees from './Settings/CourseTuitionFees.vue';

export default [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: ()=>import('./Login.vue'),
    name: 'Login'
  },
  {
    path: '/login-otp/:hash_ids',
    component: ()=>import('./LoginOtp.vue'),
    name: 'LoginOtp'
  },
  {
    path: '*',
    component: ()=>import('./NotFound.vue'),
    name: '404'
  },
  {
    path: '/dashboard',
    component: ()=>import('./DashboardV2.vue'),
    name: 'dashboard'
  },
  {
    path: '/agency-list',
    component: ()=>import('./AgencyList.vue'),
    name: 'AgencyList'
  },{
    path: '/add-new-agent',
    component: ()=>import('./AddNewAgent.vue'),
    name: 'AddNewAgent'
  },{
    path: '/add-new-multiple-agent',
    component: ()=>import('./AddNewMultipleAgentImport.vue'),
    name: 'AddNewMultipleAgent'
  },{
    path: '/settings/region-list',
    component: ()=>import('./Settings/RegionList.vue'),
    name: 'RegionList'
  },{
    path: '/settings/regional-supervisor-list',
    component: ()=>import('./Settings/RegionalSupervisor.vue'),
    name: 'RegionalSupervisor'
  },{
    path: '/settings/intake',
    component: ()=>import('./Settings/IntakeList.vue'),
    name: 'Intake'
  },{
    path: '/settings/course-level',
    component: ()=>import('./Settings/CourseLevel.vue'),
    name: 'CourseLevel'
  },{
    path: '/settings/intake-status',
    component: ()=>import('./Settings/IntakeStatus.vue'),
    name: 'IntakeStatus'
  },{
    path: '/settings/invoice-status',
    component: ()=>import('./Settings/InvoiceStatus.vue'),
    name: 'InvoiceStatus'
  },{
    path: '/settings/student-status',
    component: ()=>import('./Settings/StudentStatus.vue'),
    name: 'StudentStatus'
  },{
    path: '/settings/agent-status',
    component: ()=>import('./Settings/AgentStatus.vue'),
    name: 'AgentStatus'
  },
  {
    path: '/gateway/all-application',
    component: ()=>import('./Gateway/AllApplicationV2.vue'),
    name: 'AllApplication'
  },
  {
    path: '/gateway/advanced-student-search',
    component: ()=>import('./Gateway/AdvancedSearchStudentList.vue'),
    name: 'AdvancedSearchStudentList'
  },
  {
    path: '/gateway/intake-wise-agent-list/:id',
    component: ()=>import('./Gateway/IntakeWiseAgentList.vue'),
    name: 'IntakeWiseAgentList'
  },
  {
    path: '/gateway/agent-wise-student-list/:id/:intake_id/:active_tab?',
    component: ()=>import('./Gateway/AgentWiseStudentList.vue'),
    name: 'AgentWiseStudentList'
  },
  {
    path: '/gateway/agent-wise-confirmed-student-list/:id/:intake_id/:active_tab?',
    component: ()=>import('./Gateway/AgentWiseConfirmedStudentList.vue'),
    name: 'AgentWiseConfirmedStudentList'
  }
  /* ,{
    path: '/gateway/bulk-verfication-upload/:id',
    component: BulkVerficationUpload,
    name: 'BulkVerficationUpload'
  } */,{
    path: '/gateway/bulk-verfication-upload/:id',
    component: ()=>import('./Gateway/UniStudentBulkImport.vue'),
    name: 'BulkVerficationUpload'
  },{
    path: '/gateway/bulk-student-import-summary/:import_log_id',
    component: ()=>import('./Gateway/BulkStudentImportSummary.vue'),
    name: 'BulkStudentImportSummary'
  },{
    path: '/gateway/bulk-verfication-list/:intake_hash_ids',
    component: ()=>import('./Gateway/BulkVerficationList.vue'),
    name: 'BulkVerficationList'
  },{
    path: '/settings/statuses',
    component: ()=>import('./Settings/Statuses.vue'),
    name: 'SettingsStatuses'
  },{
    path: '/settings/commissions',
    component: ()=>import('./Settings/Commissions.vue'),
    name: 'Commissions'
  },{
    path: '/settings/email-settings',
    component: ()=>import('./Settings/EmailSettings.vue'),
    name: 'EmailSettings'
  },
  {
    path: '/settings/university-profile',
    component: ()=>import('./Settings/UniversityProfile.vue'),
    name: 'UniversityProfile'
  },
  {
    path: '/signup',
    component: ()=>import('./UniversitySignup.vue'),
    name: 'UniversitySignup'
  },
  // {
  //   path: '/agency-wise-bulk-students/:id',
  //   component: AgencyWiseBulkStudent,
  //   name: 'AgencyWiseBulkStudent'
  // },
  {
    path: '/forgot-password',
    component: ()=>import('./ForgotPassword.vue'),
    name: 'ForgotPassword'
  }, {
    path: '/reset-password/:password_reset_token',
    component: ()=>import('./ResetPassword.vue'),
    name: 'ResetPassword'
  }, {
    path: '/settings/agent-account-managers',
    component: ()=>import('./Settings/AgentAccountManagers.vue'),
    name: 'AgentAccountManagers'
  }, {
    path: '/reset-password',
    component: ()=>import('./PasswordResetAfterLogin.vue'),
    name: 'PasswordResetAfterLogin'
  }, {
    path: '/settings/users',
    component:()=>import('./Settings/Users.vue'),
    name: 'Users'
  }, {
    path: '/settings/permits',
    component: ()=>import('./Settings/Permits.vue'),
    name: 'Permits'
  }, {
    path: '/settings/add-edit-role/:id?',
    component: ()=>import('./Settings/AddEditRole.vue'),
    name: 'AddEditRole'
  }, {
    path: '/settings/role-permissions',
    component: ()=>import('./Settings/RolePermissions.vue'),
    name: 'RolePermissions'
  }, {
    path: '/gateway/import-agent-multiple-student/:intake_id',
    component: ()=>import('./Gateway/AddAgentMultipleStudent.vue'),
    name: 'AddAgentMultipleStudent'
  }, {
    path: '/gateway/communications/:query?',
    component: ()=>import('./Gateway/Communications.vue'),
    name: 'Communications'
  },
  {
    path: '/gateway/dispute-list/:query?',
    component: ()=>import('./Gateway/Dispute/DisputedIntakeList.vue'),
    name: 'DisputedIntakeList'
  },
  {
    path: '/gateway/dispute-list-by-intake/:query?',
    component: ()=>import('./Gateway/Dispute/DisputedStudentListByIntake.vue'),
    name: 'DisputedStudentListByIntake'
  },
  {
    path: '/gateway/task-list',
    component: ()=>import('./Gateway/TaskList.vue'),
    name: 'TaskList'
  }, {
    path: '/settings/marketing-suite/:id?',
    component: ()=>import('./Settings/MarketingSuiteAddAndUpdate.vue'),
    name: 'MarketingSuiteAddAndUpdate'
  }, {
    path: '/settings/marketing-suite-list',
    component: ()=>import('./Settings/MarketingSuiteList.vue'),
    name: 'MarketingSuiteList'
  }, {
    path: '/settings/marketing-suite-email-sample/:id',
    component: ()=>import('./Settings/MarketingSuiteEmailSample.vue'),
    name: 'MarketingSuiteEmailSample'
  }, {
    path: '/agent-profile/:id',
    component: ()=>import('./AgentProfile.vue'),
    name: 'AgentProfile'
  }, {
    path: '/settings/territory-list',
    component: ()=>import('./Settings/TerritoryList.vue'),
    name: 'TerritoryList'
  }, {
    path: '/settings/email-template-list',
    component: ()=>import('./Settings/EmailTemplateList.vue'),
    name: 'EmailTemplateList'
  }, {
    path: '/settings/email-template/:id?',
    component: ()=>import('./Settings/EmailTemplateAddAndUpdate.vue'),
    name: 'EmailTemplateAddAndUpdate'
  }, {
    path: '/settings/email-configuration',
    component: ()=>import('./Settings/EmailConfiguration.vue'),
    name: 'EmailConfiguration'
  }, {
    path: '/logout',
    component: ()=>import('./Logout.vue'),
    name: 'Logout'
  },
  {
    path: '/gateway/invoice-intake-list',
    component: ()=>import('./Invoice/InvoiceIntakeList'),
    name: 'InvoiceIntakeList'
  },
  {
    path: '/invoices',
    component: ()=>import('./Invoice/Invoices'),
    name: 'Invoices'
  },
  {
    path: '/invoices-details',
    component: ()=>import('./Invoice/InvoiceDetails'),
    name: 'InvoiceDetails'
  },
  {
    path: '/user-signup/:token',
    component: ()=>import('./UserSignUp.vue'),
    name: 'UserSignUp'
  },
  {
    path: '/user/change-password',
    component: ()=>import('./User/ChangePassword.vue'),
    name: 'ChangePassword'
  },
  {
    path: '/report-list',
    component: ()=>import('./Report/ReportList.vue'),
    name: 'ReportList'
  },
  {
    path: '/settings/faculty_list',
    component: ()=>import('./Settings/Faculty.vue'),
    name: 'Faculty'
  },
  {
    path: '/settings/course_list',
    component: ()=>import('./Settings/Course.vue'),
    name: 'Course'
  },
  /* {
    path: '/settings/course_tuition_fees_list',
    component: CourseTuitionFees,
    name: 'CourseTuitionFees'
  }, */
  {
    path: '/settings/school-and-course-list',
    component: ()=>import('./Settings/SchoolAndCourses.vue'),
    name: 'SchoolAndCourses'
  },
  {
    path: '/settings/school-and-course-list/:year_id',
    component: ()=>import('./Settings/CourseTuitionFees_v2.vue'),
    name: 'CourseTuitionFees'
  }, {
    path: '/settings/school-related-courses/:hash_ids',
    component: ()=>import('./Settings/SchoolRelatedCourses.vue'),
    name: 'SchoolRelatedCourses'
  }, {
    path: '/settings/import-school-multiple-courses/:hash_ids',
    component: ()=>import('./Settings/ImportSchoolMultipleCourses.vue'),
    name: 'ImportSchoolMultipleCourses'
  }, {
    path: '/notifications',
    component: ()=>import('./Notifications.vue'),
    name: 'Notifications'
  }, {
    path: '/settings/2fa-config',
    component: ()=>import('./Settings/BasicSettings.vue'),
    name: 'BasicSettings'
  },
  {
    path: '/settings/import-academic-year-wise-multiple-school-and-courses/:year_id',
    component: ()=>import('./Settings/ImportAcademicYearWiseMultipleCourses.vue'),
    name: 'ImportIntakeWiseMultipleCourses'
  },
]
